<template>
  <div class="hello">
    <div class="top1" @click="clickhide">
      <img v-if="hide" src="@/assets/03-1.png" alt="" />

      <img v-else src="@/assets/03-2.png" alt="" />
      <audio
        v-if="hide"
        id="bgmusic"
        :src="Music"
        autoplay
        loop="loop"
        style="width: 0px; height: 0px"
      />
    </div>
    <div class="top2" @click="list">
      <img src="@/assets/04.png" alt="" />
    </div>
    <div class="top3" @click="open">
      <img src="@/assets/05.png" alt="" />
    </div>
    <el-dialog
      title="二维码"
      :visible.sync="dialogVisible"
      width="30%"
      :modal="false"
    >
      <img src="@/assets/二维码.jpg" alt="" />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["posvalue"],
  data() {
    return {
      hide: false,
      Music: "",
      dialogVisible: false,
      rewardName:'',
    };
  },
  created() {
    setTimeout(() => {
      this.Music =
        `https://lottery.mabotech.com/api/upload/viewVido?fileName=` +
        localStorage.getItem("activeMusic");
    }, 1000);
    this.rewardName = localStorage.getItem("activityName")
  },
  methods: {
    clickhide() {
      if (localStorage.getItem("activeMusic") == "") {
        this.$message.error("未获取到音频");
      } else {
        this.hide = !this.hide;
      }
    },
    list() {
      this.$router.push({
        name: "WinningList",
        query: {
          rewardname: this.rewardName,
        },
      });
    },
    open() {
      this.dialogVisible = !this.dialogVisible;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.top1 {
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
  // background: #41ce09;
}
.top2 {
  width: 50px;
  margin-bottom: 15px;
  height: 50px;
  // background: #0a0dd1;
}
.top3 {
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
  // background: #c50f0f;
}
img {
  width: 100%;
  height: 100%;
}
</style>
