<template>
  <div id="app">
    <div id="nav">
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
    </div>
    <router-view />
    <div style="position: absolute; top: 31%; left: 92%; z-index: 10" v-show="$route.path !='/about'">
      <Side  />
    </div>
  </div>
</template>

<script>
import Side from "./components/Side.vue";
export default {
  components: { Side },
  props: ["passValue"],

  name: "Home",
  data() {
    return {
      list: "",
      backimg: "",
    };
  },
  created() {
    // console.log(this.$route);
  },
  methods: {
    Return() {
      // console.log(item);
      this.$router.push({
        name: "Home",
        query: {
          activityName: localStorage.getItem("activityName"),
        },
      });
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
// html{
//   font-size: ;
// }
#nav {
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
* {
  margin: 0;
  padding: 0;
}
</style>
